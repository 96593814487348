import { useEffect, useState } from "react";

const fetchNews = async (
  company_name: string,
  newstitle_contains?: string,
  limit?: number
) => {
  try {
    const jsonData = await fetch(
      `https://dxdailypost.com/api/scraping-data?companyname=${company_name}${
        newstitle_contains ? "&newstitle_contains=" + newstitle_contains : ""
      }&_limit=${limit || 5}&_sort=published_at:DESC`
    );
    const data = await jsonData.json();
    return { data, hasError: false };
  } catch (e) {
    return { hasError: true };
  }
};

const useDxNews = (company_name, newstitle_contains = "", limit?: number) => {
  const [newsData, setNewsData] = useState({
    isLoading: true,
    hasError: false,
    data: [],
  });

  useEffect(() => {
    (async () => {
      const news = await fetchNews(company_name, newstitle_contains, limit);
      if (news.hasError) {
        setNewsData({ ...newsData, isLoading: false, hasError: true });
      } else {
        setNewsData({
          isLoading: false,
          hasError: false,
          data: news?.data,
        });
      }
    })();
  }, []);

  return newsData;
};

export default useDxNews;
