import React from "react";
import { twMerge } from "tailwind-merge";
import MarkdownRenderer from "../../common/MarkdownRenderer";

const MobileAccordionTabs = ({ data, activeIndex, setActiveIndex }) => {
  const accordions = data?.details;
  return (
    <>
      <div className="w-full">
        <ul className="[&_li]:text-left">
          {accordions.map((accordion, index) => {
            const isActive = index == activeIndex;
            const background = isActive
              ? "#F7F6F3"
              : data?.theme_color || "#F7F6F3";
            const color = isActive
              ? data?.theme_color || "#2A2A2A"
              : data?.theme_color
              ? "#FFF"
              : "#2A2A2A";
            return (
              <div key={index}>
                <button
                  className={`px-[25px] py-5 text-sm leading-5 w-full ${
                    index !== accordions.length - 1 &&
                    "border-b border-[#00000015]"
                  }`}
                  style={{ background: background, color: color }}
                  onClick={() => setActiveIndex(index)}
                >
                  <li>{accordion.answer}</li>
                </button>
                <div
                  className={twMerge(
                    `bg-[#F7F6F3] p-5 [&_p]:leading-[30px] [&_ul]:list-disc [&_ul]:pl-4 ${
                      isActive ? "block" : "hidden"
                    }`
                  )}
                >
                  <MarkdownRenderer
                    markdown={accordions[activeIndex]?.content}
                  />
                </div>
              </div>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default MobileAccordionTabs;
