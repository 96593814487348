import { Link } from "gatsby";
import React from "react";

const FootBanner = () => {
  return (
    <div className="pt-[50px] pb-[58px] px-4 bg-[#8BD3E5] text-center flex justify-center [&_p]:text-[20px] [&_p]:leading-[30px] [&_p]:text-black flex-col items-center">
      <h3 className="mb-5 text-4xl leading-[50px] text-black font-bold">
        スタートアップ応援キャンペーン実施中!
      </h3>
      <p>
        スタートアップ企業に必要な顧客・コミュニケーション管理のツールを無料で利用可能。
      </p>
      <p className="mb-5">
        1,250USD（約15万円以上相当）分のクレジット特典をご提供します。
      </p>
      <Link
        to="/tp/freshworks/startups/"
        className="px-5 py-3 text-[#1C1C1C] bg-[#FFFFFF] transition-all duration-500 hover:bg-[#148FCC] rounded-[30px] hover:text-white inline-flex text-sm leading-[15px] font-medium"
      >
        スタートアップ応援キャンペーン詳細をみる
      </Link>
    </div>
  );
};

export default FootBanner;
