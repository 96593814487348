import { graphql, useStaticQuery } from "gatsby";

export const useHarnessNews = () => {
  try {
    const data = useStaticQuery(graphql`
      query FetchHarnessNews {
        harness {
          news(sort: "releaseDate:desc", pagination: { limit: 3 }) {
            data {
              attributes {
                slug
                title
                releaseDate
              }
            }
          }
        }
      }
    `);
    return data?.harness?.news?.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};
