import React from "react";
import { extractInternalLink } from "../../../utils/helpers";
import Button from "../../common/Button";
import Webinar from "../webinar/Webinar";

const SectionA = ({ data }) => {
  const purifiedLink = data?.link && extractInternalLink(data?.link);
  const secondaryPurifiedLink =
    data?.secondary_link && extractInternalLink(data?.secondary_link);
  return (
    <div
      className={`min-h-[250px] flex justify-center flex-col items-center relative ${
        data?.is_last_section ? "" : "mb-[72px]"
      }`}
      style={{ backgroundColor: data?.bg_color || "#003059" }}
    >
      <div className="[&_div]:relative w-full">
        {data?.webinar.length > 0 && <Webinar data={data.webinar} />}
      </div>
      {data?.anchor_id && (
        <span
          className="left-0 top-[-100px] absolute"
          id={data?.anchor_id.replace("#", "")}
        ></span>
      )}
      <div className="py-10  px-4">
        <h3
          className="text-[20px] sm:text-2xl leading-[34px] mb-5 font-bold text-center"
          style={{ color: data?.title_color || "#FFF" }}
        >
          {data?.title}
          <br />
          {data?.title_second_line}
        </h3>
        {data?.banner_description && (
          <p
            className="mb-4 text-sm laeding-[30px] text-center"
            style={{ color: data?.title_color || "#FFF" }}
          >
            {data?.banner_description}
          </p>
        )}
        <div className="flex flex-col sm:flex-row justify-center gap-5 [&_div]:justify-center [&_div]:flex">
          {purifiedLink && (
            <Button
              isExternal={!purifiedLink.startsWith("/")}
              path={purifiedLink}
              title={data?.link_text}
              size="normal"
              className="py-3 px-4 sm:px-8 text-base leading-5 text-center"
              textSx={{ color: data?.btn_text_color || "#000" }}
              buttonSx={{ backgroundColor: data?.btn_theme_color || "#FFF" }}
              textHoverSx={{ color: data?.btn_theme_color }}
              buttonHoverSx={{
                backgroundColor: "transparent",
                border: `1px solid ${data?.btn_theme_color}`,
              }}
            />
          )}
          {secondaryPurifiedLink && (
            <Button
              isExternal={!secondaryPurifiedLink.startsWith("/")}
              path={secondaryPurifiedLink}
              title={data?.secondary_link_text}
              size="normal"
              className="py-3 px-4 sm:px-8 text-base text-center leading-5"
              textSx={{ color: data?.btn_text_color || "#000" }}
              buttonSx={{ backgroundColor: data?.btn_theme_color || "#FFF" }}
              textHoverSx={{ color: data?.btn_theme_color }}
              buttonHoverSx={{
                backgroundColor: "transparent",
                border: `1px solid ${data?.btn_theme_color}`,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionA;
