import React, { useState } from "react";
import PricingCard from "../pricing-card";
import PricingProductCard from "../pricing-product-card";

type IPricingData = {
  title: string;
  summary: string;
};

const pricingPlan: IPricingData[] = [
  {
    title: "Basic Plan",
    summary:
      "ウェブサイトを自動かつ安全にサポート。毎月のスキャンと自動言語検出",
  },
  {
    title: "Plus Plan",
    summary:
      "Basicプランに加え、高度なプライバシーポリシージェネレーターが付属",
  },
  {
    title: "Business Plan",
    summary:
      "Plusプランに加えジオターゲティング、クッキーポリシー、カスタムCSSに対応",
  },
  {
    title: "Enterprise",
    summary:
      "先進のエンタープライズ機能とグローバルカバレッジ。ボリュームディスカウントあり",
  },
];

const pricingProducts: string[] = [
  "GDPR（欧州）",
  "CCPA（カリフォルニア州）",
  "LGPD（ブラジル）",
];

const pricings = {
  "Basic Plan": [100, 140, 170],
  "Plus Plan": [150, 210, 260],
  "Business Plan": [250, 350, 430],
  Enterprise: [0, 0, 0],
};

function SecurePrivacyPricingSection() {
  const [enabledProductCount, setEnabledProductCount] = useState(1);

  return (
    <div className="mb-[72px] px-4 lg:px-20 relative">
      <span className="absolute top-[-200px] left-0" id="pricing"></span>
      <h3 className="text-2xl leading-7 text-dsc-title mb-7 font-bold text-center">
        価格
      </h3>
      <h4 className="text-lg leading-6 text-dsc-title mb-20 font-bold text-center">
        御社のビジネスに最適なプランをお選びください
      </h4>
      <div className="flex flex-col  items-center xl:items-start xl:flex-row xl:justify-between max-w-full gap-x-4 gap-y-8 ">
        <div className="max-w-full h-fit flex justify-center">
          <PricingProductCard
            pricingProducts={pricingProducts}
            setEnableProductCount={setEnabledProductCount}
          />
        </div>
        <div className="flex items-stretch pb-5 overflow-x-auto scrollbar gap-6 w-fit max-w-full">
          {pricingPlan?.map((plan) => (
            <div key={plan.title} className="self-auto">
              <PricingCard
                pricing={pricings[plan.title][enabledProductCount - 1]}
                {...plan}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default SecurePrivacyPricingSection;
