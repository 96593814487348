import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/common/Layout";
import Meta from "../../components/common/Meta";
import { ProductDetails } from "../../types/products";
import { componentsMapper } from "../../utils/productComponentMapper";

const ProductTpPage: React.FC<ProductDetails> = ({ data }) => {
  console.log(data);
  const product = data?.products?.nodes[0];
  const productSEO = product.SEO;
  const meta = {
    title:
      productSEO?.meta_title ||
      `${product.name}｜正規販売代理店（株）Digital Stacks`,
    description: productSEO?.meta_description,
    pathname: `/tp/${product.slug}/`,
    image: productSEO?.featured_image?.url
      ? `https://strapi-admin.digitalstacks.net${productSEO.featured_image.url}`
      : "",
  };
  return (
    <Layout>
      <Meta meta={meta} />
      <>{componentsMapper(product.Sections, product.navigation)}</>
    </Layout>
  );
};
export default ProductTpPage;

export const query = graphql`
  query FetchProductDetails($pageId: String!) {
    products: allStrapiProduct(filter: { slug: { eq: $pageId } }) {
      nodes {
        slug
        name
        navigation {
          slug
          name
        }
        SEO {
          meta_description
          meta_title
          featured_image {
            url
          }
        }
        Sections {
          ... on STRAPI__COMPONENT_SECTIONS_HERO_SECTION {
            id
            title
            title_color
            other_text_color
            summary
            subtitle
            has_no_margin
            seconday_link
            link
            icon_title {
              alternativeText
              url
              localFile {
                childrenImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    height: 40
                    width: 245
                  )
                }
              }
            }
            link_color
            link_hover_color
            link_text_color
            link_text_hover_color
            link_text
            secondary_link_text
            secondary_link_bg_color
            secondary_link_text_color
            secondary_link_hover_border_color
            secondary_link_hover_text_color
            bg_color
            banner_img {
              alternativeText
              url
              localFile {
                childrenImageSharp {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                }
              }
            }
            background_img {
              alternativeText
              url
              localFile {
                childrenImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 100
                    placeholder: NONE
                  )
                }
              }
            }
            webinar {
              title
              sub_title
              link
              bg_color
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_PRODUCTS_HELPER_NAVIGATION {
            id
            strapi_component
            shortcode
          }
          ... on STRAPI__COMPONENT_SECTIONS_OVERVIEW {
            id
            strapi_component
            btn_text
            anchor_id
            btn_bg_color
            btn_text_color
            has_no_margin
            btn_link
            title
            is_default_title_hidden
            is_title_center
            description {
              data {
                description
              }
              medias {
                alternativeText
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                  }
                }
              }
            }
            image {
              alternativeText
              url
              localFile {
                childrenImageSharp {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_SECTIONS_FEATURE {
            id
            strapi_component
            link
            title
            anchor_id
            section_title
            description {
              data {
                description
              }
              medias {
                alternativeText
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                  }
                }
              }
            }
            image {
              alternativeText
              url
              localFile {
                childrenImageSharp {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_SECTIONS_FUNCTION {
            id
            title
            strapi_component
            theme_color
            anchor_id
            details {
              answer
              content {
                data {
                  content
                }
              }
              medias {
                alternativeText
                url
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                  }
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_SECTIONS_PRICING {
            id
            plans {
              yearly_price
              strapi_id
              plan_description
              plan_name
              plan_detail {
                data {
                  plan_detail
                }
              }
              monthly_price
            }
            anchor_id
            pricing_description: description
            title
            custom_link
            custom_link_text
            btn_color_theme
            strapi_component
          }
          ... on STRAPI__COMPONENT_PRODUCTS_WHY_CHOOSE_US {
            id
            cards {
              title
              summary
              btn
            }
            summary
            title
            strapi_component
          }
          ... on STRAPI__COMPONENT_PRODUCTS_HARNESS_PRICING {
            id
            strapi_component
            title
            anchor_id
            theme_color
            plan {
              plan
              plan_detail {
                data {
                  plan_detail
                }
              }
              price
              summary
              tooltip__text
              strapi_id
            }
          }
          ... on STRAPI__COMPONENT_SECTIONS_NEWS_SECTION {
            id
            company_name
            related_keywords
            component_shortcode
            strapi_component
          }
          ... on STRAPI__COMPONENT_PRODUCTS_FRESHWORKS {
            id
            strapi_component
            component_name
          }
          ... on STRAPI__COMPONENT_SECTIONS_SOULTION {
            id
            num_of_columns
            title
            strapi_component
            anchor_id
            solution_description: description
            button_link
            button_text
            button_bg_color
            button_text_color
            cards {
              title
              description
              long_description
              icon {
                alternativeText
                localFile {
                  childrenImageSharp {
                    gatsbyImageData(height: 50, width: 50)
                  }
                }
                url
              }
            }
          }
          ... on STRAPI__COMPONENT_PRODUCTS_SECURE_PRIVACY_PRICING {
            id
            strapi_component
            shortcode
          }
          ... on STRAPI__COMPONENT_PRODUCTS_SECURE_PRIVACY_PRICING {
            id
            strapi_component
            strapi_id
          }
          ... on STRAPI__COMPONENT_SECTIONS_SECTION_A {
            id
            strapi_component
            link
            secondary_link
            secondary_link_text
            title
            anchor_id
            is_last_section
            title_color
            title_second_line
            btn_theme_color
            btn_text_color
            link_text
            banner_description: description
            bg_color
            webinar {
              sub_title
              title
              bg_color
              link
            }
          }
        }
      }
    }
  }
`;
