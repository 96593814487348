import React from "react";

const ServiceCard = ({ data }) => {
  return (
    <div className="p-5 w-full lg:w-[300px] lg:h-[280px] shadow-[0px_2px_6px_#00000040] rounded-[10px] bg-white">
      <div className="mb-5">{data.title}</div>
      <ul className="pl-4 list-disc text-sm leading-[25px] text-black">
        {data.list.map((listItem, index) => (
          <li key={index}>{listItem}</li>
        ))}
      </ul>
    </div>
  );
};

export default ServiceCard;
