import React from "react";
import About from "./About";
import FootBanner from "./FootBanner";
import HeroSection from "./Hero";
import OtherFeatures from "./OtherFeatures";
import DxNews from "../products/news-section/DxNews";

const FreshWorks = () => {
  return (
    <>
      <HeroSection />
      <About />
      <OtherFeatures />
      <div className="bg-[#F7F7F7] [&_div:first-child]:mb-0 pb-20 sm:px-4">
        <DxNews data={{ company_name: "Freshworks", related_keywords: null }} />
      </div>
      <FootBanner />
    </>
  );
};

export default FreshWorks;
