import React from "react";
import ReactTooltip from "react-tooltip";
import MarkdownRenderer from "../../common/MarkdownRenderer";
import Button from "../../common/Button";
const Plan = ({ data, index, theme_color = "#00ade5" }) => {
  const plan_details = data?.plan_detail?.data?.plan_detail;
  return (
    <div
      className="bg-[#f9f9f9] min-w-[300px] p-4 pb-8 flex flex-col gap-4 basis-[30%] grow-0 [&_.tooltip]:max-w-[300px] [&_.tooltip]:text-xs"
      style={
        index === 1
          ? {
              borderTop: `5px solid ${theme_color}`,
              boxShadow: "0 0 10px rgba(186, 186, 186,.7)",
            }
          : {}
      }
    >
      <ReactTooltip place="top" className="tooltip" backgroundColor="#000" />
      <div className="flex flex-col gap-2 pb-4 text-center border-b border-b-hn-muted border-opacity-10">
        <h3 className="text-xl font-bold text-hn-primary">{data?.plan}</h3>
        <p className="text-base text-hn-muted">{data?.summary}</p>
        <p className={"block mx-auto cursor-pointer max-w-max"}>
          {data?.price}
          {data?.tooltip__text && (
            <svg
              data-tip={data?.tooltip__text}
              xmlns="http://www.w3.org/2000/svg"
              className="inline w-5 h-5 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
              style={{ color: theme_color }}
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </p>
      </div>
      <div className="flex justify-center">
        <Button
          path="/contact-jp/"
          size="normal"
          title="お問い合わせ"
          buttonSx={{
            backgroundColor: "#ffffff",
            color: `${theme_color}`,
            border: `1px solid ${theme_color}`,
          }}
          textHoverSx={{ color: "#ffffff" }}
          buttonHoverSx={{
            backgroundColor: `${theme_color}`,
            border: `1px solid ${theme_color}`,
          }}
        />
      </div>
      <div>
        {plan_details && (
          <div className="pt-4 leading-5 [&_li]:mb-2 [&_ul]:list-disc [&_ul]:pl-6 [&_li]:text-sm [&_p]:text-sm [&_li]:text-dsc-body [&_p:not(:last-child)]:mb-2 [&_strong]:text-dsc-title [&_strong]:font-bold [&_ul:not(:last-child)]:mb-2 [&_hr]:my-4">
            <MarkdownRenderer markdown={plan_details} />
          </div>
        )}
      </div>
    </div>
  );
};
export default Plan;
