import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { purifiyImageData } from "../../../utils/helpers";
import MarkdownRenderer from "../../common/MarkdownRenderer";
import TwoColGrid from "../../common/TwoColGrid";

type ProductFeatureType = {
  data: {
    title: string;
    section_title?: string;
    anchor_id?: string;
    description: {
      data: {
        description: string;
      };
      medias?: object[];
    };
    image?: {
      alternativeText: string;
    };
    link: string;
  };
  isReversed?: boolean;
};

const Feature: React.FC<ProductFeatureType> = ({ data, isReversed }) => {
  const bannerImage = data?.image && purifiyImageData(data?.image);
  return (
    <div className="px-4 lg:px-20 pb-[72px] relative">
      {data?.anchor_id && (
        <span
          className="absolute left-0 top-[-150px]"
          id={data?.anchor_id?.replace("#", "")}
        ></span>
      )}
      {data?.section_title && (
        <h2 className="text-dsc-title dsc-h2 text-center leading-7 mb-12">
          {data.section_title}
        </h2>
      )}
      <div>
        <TwoColGrid
          firstSection={
            <TextSection
              title={data?.title}
              markdown={data.description}
            />
          }
          isReversed={isReversed}
          secondSection={
            bannerImage?.gatsbyImageData ? (
              <GatsbyImage
                image={bannerImage.gatsbyImageData}
                objectFit="cover"
                alt={bannerImage.alt}
              />
            ) : bannerImage?.url ? (
              <img src={bannerImage.url} loading="lazy" alt={bannerImage.alt} />
            ) : null
          }
        />
      </div>
    </div>
  );
};

const TextSection = ({ markdown, title }) => {
  return (
    <div>
      <h3 className="mb-6 text-[20px] text-dsc-title leading-6 font-bold">
        {title}
      </h3>
      <div className="prose prose-headings:text-dsc-title prose-headings:font-normal prose-headings:text-lg prose-headings:mb-2 w-full max-w-none [&_p]:text-sm [&_p]:leading-[22px] text-dsc-body [&_p]:mb-4 [&_ol]:list-decimal [&_ol]:pl-6 [&_ul]:list-disc [&_ul]:pl-6 [&_li]:mb-1 [&_a]:text-sm [&_a]:leading-4 [&_a]:text-dsc-button [&_a]:hover:text-dsc-button/50 [&_a]:no-underline">
        <MarkdownRenderer markdown={markdown} />
      </div>
    </div>
  );
};

export default Feature;
