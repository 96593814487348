import React from "react";
import Plan from "./plans";

const PremiumPricing = ({ data }) => {
  return (
    <div className=" px-4 lg:px-20 pb-[72px] relative">
      {data?.anchor_id && (
        <span
          className="absolute top-[-100px] left-0"
          id={data.anchor_id.replace("#", "")}
        ></span>
      )}
      <h2 className="text-center text-2xl leading-7 font-bold mb-12">
        {data?.title}
      </h2>
      <div className="flex overflow-x-auto pb-4 scrollbar flex-row justify-center gap-x-4 gap-y-8">
        {data?.plan?.map((plan, index) => (
          <Plan
            data={plan}
            index={index}
            key={index}
            theme_color={data?.theme_color}
          />
        ))}
      </div>
    </div>
  );
};

export default PremiumPricing;
