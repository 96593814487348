import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { twMerge } from "tailwind-merge";
import { extractInternalLink, purifiyImageData } from "../../../utils/helpers";
import Button from "../../common/Button";

const Solution = ({ data }) => {
  const columns = Number(data?.num_of_columns) || 4;
  const buttonLink =
    data?.button_link && extractInternalLink(data?.button_link);

  return (
    <div className="px-4 lg:px-20 pb-[72px] relative">
      {data?.anchor_id && (
        <span
          id={data.anchor_id.replace("#", "")}
          className="absolute top-[-150px] left-0"
        ></span>
      )}
      {data?.title && (
        <h2 className="text-center text-2xl leading-7 text-dsc-title mb-5 font-bold">
          {data?.title}
        </h2>
      )}
      {data?.solution_description && (
        <p className={`text-sm leading-7 text-dsc-body text-center mb-5`}>
          {data.solution_description}
        </p>
      )}
      <div
        className={twMerge(
          `grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-10 py-10 gap-y-10 ${
            columns == 3 && "lg:grid-cols-3"
          } ${columns == 2 && "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2"}`
        )}
      >
        {data?.cards.map((card, index) => {
          const icon = purifiyImageData(card?.icon);
          return (
            <div
              className={`flex flex-col ${columns > 2 && "items-center"}`}
              key={index}
            >
              {icon.gatsbyImageData !== undefined ? (
                <GatsbyImage
                  className="mb-5"
                  image={icon.gatsbyImageData}
                  alt={icon.alt}
                />
              ) : (
                icon.url && (
                  <img
                    src={icon.url}
                    alt={icon.alt}
                    loading="lazy"
                    width="50px"
                    height="50px"
                    className="mb-5"
                  />
                )
              )}
              <h4
                className={`text-dsc-title font-bold leading-7 text-base mb-5 ${
                  columns > 2 && "text-center"
                }`}
              >
                {card.title}
              </h4>
              <p
                className={`text-sm leading-7 mb-5 text-dsc-body ${
                  columns > 2 && "text-center"
                }`}
              >
                {card?.description || card?.long_description}
              </p>
            </div>
          );
        })}
      </div>
      {buttonLink && (
        <div className="flex justify-center items-center">
          <Button
            path={buttonLink}
            size="normal"
            isExternal={!buttonLink.startsWith("/")}
            title={data?.button_text}
            buttonSx={{ backgroundColor: data?.button_bg_color }}
            textSx={{ color: data?.button_text_color }}
            buttonHoverSx={{
              backgroundColor: "transparent",
              border: `1px solid ${data?.button_bg_color}`,
            }}
            textHoverSx={{ color: data?.button_bg_color }}
            className="py-3 px-[32px]"
          />
        </div>
      )}
    </div>
  );
};

export default Solution;
