import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Feature from "./Feature";
import Title from "./Title";

const OtherFeatures = () => {
  return (
    <div className="bg-[#F7F7F7] pb-[30px] px-4">
      {features.map((feature, index) => (
        <div className="max-w-[920px] mx-auto mb-[50px]" key={index}>
          <Title icon={feature.title.icon} title={feature.title.text} />
          <div className="flex flex-col gap-y-[10px]">
            {feature.features.map((subFeature) => (
              <Feature
                title={subFeature.title}
                summary={subFeature.summary}
                key={subFeature.id}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OtherFeatures;

const features = [
  {
    title: {
      icon: (
        <StaticImage
          src="../../../static/images/tp/fsales.png"
          width={43}
          height={45}
          layout="fixed"
          alt="Freshsales"
        />
      ),
      text: "Freshsales",
    },
    features: [
      {
        id: 1,
        title: "Freshsales",
        summary: "状況に応じた営業で収益アップのスピードを加速しましょう。",
      },
      {
        id: 2,
        title: "Freshsales Suite",
        summary:
          "オールインワンのCRMで販売とマーケティングをシームレスに行います",
      },
    ],
  },
  {
    title: {
      icon: (
        <StaticImage
          src="../../../static/images/tp/fservice.png"
          width={43}
          height={45}
          layout="fixed"
          alt="Freshservice"
        />
      ),
      text: "Freshservice",
    },
    features: [
      {
        id: 1,
        title: "Freshservice",
        summary: "最適な規模でITサービスを管理しましょう。",
      },
    ],
  },
];
