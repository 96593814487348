import React from "react";
import { BiChevronRight, BiChevronDown } from "react-icons/bi";
import useWindowSize from "../../../hooks/useWindowSize";

const WhyChooseUs = ({ data }) => {
  const screen = useWindowSize();
  return (
    <div className="px-4 lg:px-0 pb-[72px]">
      <h3 className="text-dsc-title text-[20px] leading-7 font-bold text-center mb-6">
        {data?.title}
      </h3>
      <p className="text-dsc-body text-sm leading-4 text-center">
        {data?.summary}
      </p>
      <div className="pt-[60px] flex flex-col md:flex-row justify-center items-stretch px-4 lg:px-0">
        {data?.cards?.map((card, index) => {
          return (
            <>
              <div className="px-5 py-[30px] md:w-[243px] h-[225px] bg-[#f7f6f3] rounded">
                <div className="p-2 bg-black flex justify-center align-center rounded-full text-white font-bold mb-5">
                  {card?.btn}
                </div>
                <h3 className="text-dsc-title font-bold text-base leading-6 mb-5 text-center">
                  {card?.title}
                </h3>
                <p className="text-sm leading-5 text-dsc-body text-center">
                  {card?.summary}
                </p>
              </div>
              {index !== data?.cards?.length - 1 && (
                <div className="min-h-[54px] md:min-h-[100%] md:w-[54px] flex justify-center items-center">
                  {screen?.width && screen.width > 767 ? (
                    <BiChevronRight size={42} color="#000000" />
                  ) : (
                    <BiChevronDown size={42} color="#000000" />
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default WhyChooseUs;
