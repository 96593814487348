import React from 'react'
import { twMerge } from 'tailwind-merge'

type TwoColGridTypes = {
  firstSection: React.ReactNode,
  secondSection: React.ReactNode,
  isReversed?: boolean,
  className?: string,
}

const TwoColGrid: React.FC<TwoColGridTypes> = ({ firstSection, secondSection, isReversed = false, className = "" }) => {
  return (
    <div className={twMerge(`flex flex-col sm:flex-row-reverse gap-x-10 gap-y-4 sm:gap-y-12 sm:items-center ${className} ${isReversed && 'sm:flex-row'}`)}>
      <div className="flex-1">{firstSection}</div>
      {secondSection && <div className="flex-1 flex justify-center sm:justify-start">{secondSection}</div>}
    </div>
  )
}

export default TwoColGrid