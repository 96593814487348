import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import ReactMarkdown from "react-markdown";
import RehypeRaw from 'rehype-raw'
import { extractInternalLink, purifiyImageData } from "../../utils/helpers";
import { LocalFile } from "../../types/products";

/* eslint @typescript-eslint/no-unused-vars: 0 */

type Media = {
  alternativeText: string;
  url: string;
  localFile: LocalFile;
};

type RichText = {
  data: object;
  medias: Media[];
};

type MarkdownRendererTypes = {
  markdown: string | RichText;
};

let _medias = [];

const MarkdownRenderer: React.FC<MarkdownRendererTypes> = ({ markdown }) => {
  let text;
  if (typeof markdown == 'string') {
    text = markdown;
  } else {
    for (const n in markdown.data) {
      if (typeof markdown.data[n] == 'string') {
        text = markdown.data[n];
        break;
      }
    }
    _medias = markdown.medias;
  }
  return (
    <ReactMarkdown components={componentMappings} rehypePlugins={[RehypeRaw]}>{text}</ReactMarkdown>
  );
};

const componentMappings = {
  h1: ({ node, ...props }) => (
    <h1 className="dsc-h1 mb-4 text-dsc-title" {...props} />
  ),
  h2: ({ node, ...props }) => (
    <h2 className="dsc-h2 mb-4 text-dsc-title" {...props} />
  ),
  p: ({ node, ...props }) => {
    return node.children.filter(ch => ch.tagName == 'img').length ?
      ( <div {...props} /> ) :
      ( <p className="dsc-p text-dsc-body" {...props} /> );
  },
  a: ({ node, ...props }) => {
    const link = extractInternalLink(props?.href);
    if (link.startsWith("/")) {
      return (
        <Link to={link} {...props} className="text-dsc-link" />
      );
    } else if (link.startsWith("#")) {
      return (
        <a
          {...props}
          className="text-dsc-link"
        />
      );
    }
    return (
      <a
        {...props}
        target="_blank"
        rel="noreferrer"
        className="text-dsc-link"
      />
    );
  },
  img: ({ node, ...props }) => {
    const image = purifiyImageData(_medias.filter(m => m.url.indexOf(props.src) != -1)[0]);
    return image?.gatsbyImageData ? (
      <GatsbyImage
        className="mb-5"
        image={image.gatsbyImageData}
        alt={props.alt}
      />
    ) : (
      image.url && (
        <img
          src={image.url}
          alt={image.alt}
          loading="lazy"
          className="mb-5"
        />
      )
    );
  },
};

export default MarkdownRenderer;
