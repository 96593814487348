import React from "react";
import { useHarnessNews } from "../../../hooks/useHarnessNews";
import { usePagerDutyNews } from "../../../hooks/usePagerDutyNews";
import { useRundeckNews } from "../../../hooks/useRundeckNews";
import Button from "../../common/Button";
import ProductNewsCard from "./ProductNewsCard";

export const availableQueries = ["RunDeck", "Harness", "PagerDuty"];

export const StaticQueries = ({ company_name }) => {
  switch (company_name) {
    case "Rundeck":
      return <RundeckNews />;
      break;
    case "PagerDuty":
      return <PagerDutyNews />;
      break;
    case "Harness":
      return <HarnessNews />;
      break;
    default:
      return <></>;
  }
};

const HarnessNews = () => {
  const data = useHarnessNews();
  const baseLink = `https://harness.digitalstacks.net/news/`;
  if (!data) {
    return <></>;
  }
  return (
    <>
      {data.map((news, index) => {
        const newsData = news?.attributes;
        return (
          <ProductNewsCard
            key={index}
            newsTitle={newsData?.title}
            releaseDate={newsData?.releaseDate}
            link={`${baseLink}${newsData?.slug}/`}
            companyName={"Harness"}
          />
        );
      })}
      <div className="flex lg:gap-x-9 justify-center gap-x-9 [&_a]:block [&_a]:flex-none mt-10">
        <Button
          title="BLOG一覧へ"
          path="https://harness.digitalstacks.net/blog/"
          isExternal={true}
          size="normal"
          variant="outline"
          className="border-[#074377] text-[#074377] hover:text-white hover:bg-[#074377]"
        />
        <Button
          title="NEWS一覧へ"
          path="https://harness.digitalstacks.net/news/"
          isExternal={true}
          size="normal"
          variant="normal"
          className="bg-[#074377] hover:bg-transparent hover:border-[#074377] hover:text-[#074377]"
        />
      </div>
    </>
  );
};

const PagerDutyNews = () => {
  const data = usePagerDutyNews();
  const baseLink = `https://pagerduty.digitalstacks.net/blog/`;
  if (!data) {
    return <></>;
  }
  return (
    <>
      {data.map((news, index) => {
        const newsData = news?.attributes;
        return (
          <ProductNewsCard
            key={index}
            newsTitle={newsData?.title}
            releaseDate={newsData?.date}
            link={`${baseLink}${newsData?.slug?.replaceAll("/", "")}/`}
            companyName={"PagerDuty"}
          />
        );
      })}
      <div className="flex lg:gap-x-9 justify-center gap-x-6  md:gap-x-9 [&_a]:block [&_a]:flex-none mt-10">
        <Button
          title="BLOG一覧へ"
          path="https://pagerduty.digitalstacks.net/blog/"
          isExternal={true}
          size="normal"
          variant="outline"
          className="border-[#189e3f] text-[#189e3f] hover:text-white hover:bg-[#189e3f]"
        />
        <Button
          title="NEWS一覧へ"
          path="https://pagerduty.digitalstacks.net/news/"
          isExternal={true}
          size="normal"
          variant="normal"
          className="bg-[#189e3f] hover:bg-transparent hover:border-[#189e3f] hover:text-[#189e3f]"
        />
      </div>
    </>
  );
};

const RundeckNews = () => {
  const data = useRundeckNews();
  const baseLink = `https://rundeck.digitalstacks.net/new/`;
  if (!data) {
    return <></>;
  }
  return (
    <>
      {data.map((news, index) => {
        const newsData = news?.attributes;
        return (
          <ProductNewsCard
            key={index}
            newsTitle={newsData?.title}
            releaseDate={newsData?.releaseDate}
            link={`${baseLink}${newsData?.slug}`}
            companyName={"Rundeck"}
          />
        );
      })}
      <div className="flex lg:gap-x-9 justify-center gap-x-6  md:gap-x-9 [&_a]:block [&_a]:flex-none mt-10">
        <Button
          title="NEWS一覧へ"
          path="https://rundeck.digitalstacks.net/news/"
          isExternal={true}
          size="normal"
          variant="normal"
          className="bg-[#e61e19] hover:bg-transparent hover:text-[#e61e19] hover:border-[#e61e19]"
        />
      </div>
    </>
  );
};
