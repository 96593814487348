import { graphql, useStaticQuery } from "gatsby";

export const usePagerDutyNews = () => {
  try {
    const data = useStaticQuery(graphql`
      query FetchPagerDutyNews {
        pagerduty {
          blogPages(sort: "date:desc", pagination: { limit: 3 }) {
            data {
              attributes {
                slug
                title
                date
              }
            }
          }
        }
      }
    `);
    return data?.pagerduty?.blogPages?.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};
