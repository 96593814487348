import React from "react";
import { WebinarNode } from "../../../types/product-components";
import { extractInternalLink } from "../../../utils/helpers";

interface WebinarProps {
  data: WebinarNode[];
}

const Webinar: React.FC<WebinarProps> = ({ data }) => {
  return (
    <div className="relative xl:absolute z-[2] justify-center sm:justify-between left-0 top-0 w-full flex gap-x-2 md:gap-x-4 px-4 xl:px-10">
      {data.map((webinar, index) => {
        const link = extractInternalLink(webinar.link);
        return (
          <a
            href={link}
            target={link.startsWith("/") ? "" : "_blank"}
            className="left-0 px-3 md:px-6 py-3 md:py-4 rounded-b-[10px] w-full lg:min-w-fit xl:w-fit"
            style={{ backgroundColor: webinar.bg_color }}
            key={index}
          >
            <div
              className={`flex flex-col xl:flex-row justify-between text-[#fffefe] gap-x-6 xl:items-center font-bold ${
                data.length == 1 ? "items-center" : ""
              }`}
            >
              <div>
                {index == 1 ? (
                  <span className="text-sm md:text-xl">{webinar?.title}</span>
                ) : (
                  <span className=" text-xs md:text-sm">
                    {webinar?.sub_title}
                  </span>
                )}
              </div>
              <div className="flex items-center justify-start xl:justify-between gap-1 xl:gap-x-6">
                {index == 1 ? (
                  <span className="text-xs md:text-sm">
                    {webinar?.sub_title}
                  </span>
                ) : (
                  <span className="text-sm md:text-xl">{webinar?.title}</span>
                )}
                <img
                  width="22px"
                  height="22px"
                  src="/images/tp/play_icon.svg"
                  alt="Play Icon"
                />
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default Webinar;
