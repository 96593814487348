import { Link } from "gatsby";
import React from "react";

interface FeatureProps {
  title: string;
  summary: string;
}

const Feature: React.FC<FeatureProps> = ({ title, summary }) => {
  return (
    <div className="bg-white border border-[#DDDDDD] rounded-[10px] gap-x-4 gap-y-5 flex flex-col md:flex-row justify-between items-center  sm:px-10 py-6 px-5 sm:py-5">
      <div className="text-center md:text-left w-full max-w-[640px]">
        <h3 className="text-[20px] leading-7 text-black font-bold mb-2 tracking-[.025em]">
          {title}
        </h3>
        <p className="text-black text-sm leading-6">{summary}</p>
      </div>
      <div>
        <Link
          to="/tp/freshworks/free-trial/"
          className="border border-[#BBBBBB] text-sm text-black leading-4 py-3 px-6 rounded-[3px] shadow-[0px_2px_2px_#0000001a] flex items-center justify-center min-w-[150px] hover:bg-[#148FCC] hover:border-[#148FCC] transition-all duration-300 hover:text-white"
        >
          無料トライアル
        </Link>
      </div>
    </div>
  );
};

export default Feature;
