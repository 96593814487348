import React, { useEffect, useRef, useState } from "react";
import { Navigation } from "../../../types/products";
import { useLocation } from "@reach/router";
import { twMerge } from "tailwind-merge";

interface NavigationHelperProps {
  navigation: Navigation[];
}

const NavigationHelper: React.FC<NavigationHelperProps> = ({ navigation }) => {
  const anchor_id = useLocation().hash;
  const navigationRef = useRef<HTMLDivElement>(null);
  const [isFixedNavi, toggleFixedNavi] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
    }
    function handleScroll() {
      const currentPos =
        navigationRef.current &&
        navigationRef.current.getBoundingClientRect().top;
      if (currentPos !== null && currentPos <= 50 && isFixedNavi == false) {
        toggleFixedNavi(true);
      } else if (window.pageYOffset <= 420) {
        toggleFixedNavi(false);
      }
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={twMerge(
        `md:border-b mb-[72px] border-dsc-softgray sticky top-[66px] xl:top-[72px] left-0 bg-white z-[6] ${
          isFixedNavi && "mb-0"
        }`
      )}
      ref={navigationRef}
    >
      <div className="md:flex items-center px-4 lg:px-20 overflow-x-auto overflow-y-hidden hidden max-h-10">
        {navigation.map((navigation, index) => {
          return (
            <div
              className={`max-h-10 w-full min-w-1/6 xl:min-w-[140px] [&_button]:transition-all [&_button]:duration-300 flex justify-center hover:[&_button]:text-dsc-darkshade items-center  ${
                anchor_id == navigation.slug &&
                "border-[#3480E3] [&_a]:text-dsc-darkshade [&_button]:text-dsc-darkshade border-b-4"
              }`}
              key={index}
            >
              <a
                href={navigation.slug}
                className={` text-dsc-softgray font-bold hover:text-dsc-darkshade transition-all duration-500 text-sm leading-[14px] py-4`}
              >
                {navigation.name}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NavigationHelper;
