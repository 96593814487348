import Link from "gatsby-link";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import useWindowSize from "../../hooks/useWindowSize";

const HeroSection = () => {
  const screen = useWindowSize();
  return (
    <div
      className={`w-full px-4 lg:px-0 flex flex-col relative h-[465px] py-10 lg:h-[400px] justify-center items-center font-main`}
    >
      {screen?.width && screen.width > 760 ? (
        <StaticImage
          src="../../../static/images/tp/FreshworksDesktopBg.png"
          layout="fullWidth"
          objectFit="cover"
          alt="Freshworks Banner"
          loading="eager"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: -1,
          }}
          quality={100}
        />
      ) : (
        <StaticImage
          src="../../../static/images/tp/FreshworksMobilesBg.png"
          layout="fullWidth"
          objectFit="cover"
          alt="Freshworks Mobile Banner"
          loading="eager"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: -1,
          }}
          quality={100}
        />
      )}

      <h1 className="text-[28px] font-bold leading-[45px] text-white mb-5">
        Freshworks
      </h1>
      <h2 className="text-[20px] leading-7 text-white font-bold">
        最新の顧客・従業員体験ソフトウェア
      </h2>
      <p className="max-w-[600px] mx-auto text-base text-white leading-[30px] mb-[30px] text-center">
        50,000 を超える企業がFreshworksのSaaSを使用して、カスタマー
        エクスペリエンス (CX、CRM) と従業員エクスペリエンス (ITSM、HRSM)
        を向上させています。
      </p>
      <div className="flex gap-x-5 items-center justify-center gap-y-4 flex-wrap [&_a]:text-[15px] [&_a]:leading-[15px] [&_a]:font-medium [&_a]:px-[32px] [&_a]:py-3 [&_a]:rounded-full [&_a]:transition-all [&_a]:duration-500">
        <Link
          className="text-white bg-[#148FCC] hover:bg-[#59ABEB]"
          to="/contact-jp/"
        >
          お問い合わせ
        </Link>
        <Link
          className="text-black bg-white hover:bg-[#148FCC] hover:text-white"
          to="/tp/freshworks/startups/"
        >
          スタートアップ応援キャンペーン
        </Link>
      </div>
    </div>
  );
};

export default HeroSection;
