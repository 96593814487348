import React from "react";
import { extractInternalLink } from "../../../../utils/helpers";
import Button from "../../../common/Button";
import MarkdownRenderer from "../../../common/MarkdownRenderer";

const PricingCard = ({
  plan,
  isMonthly,
  theme_color,
  custom_link,
  custom_link_text,
}) => {
  const plan_details = plan?.plan_detail?.data?.plan_detail;
  return (
    <div className="basis-[100%] min-w-[300px] md:basis-[30%] pt-6 pb-10 px-4 rounded-md bg-dsc-white-smoke">
      <div className="flex flex-col items-center gap-2 border-b pb-4 border-opacity-20">
        {plan?.plan_name && (
          <span className={`font-bold text-xl leading-7 text-dsc-title`}>
            {plan?.plan_name}
          </span>
        )}
        {plan?.monthly_price && plan?.monthly_price === "[place_holder]" ? (
          <div className="lg:min-h-[42px]"></div>
        ) : (
          <span
            className={`text-2xl leading-[42px] text-dsc-title font-bold  text-center ${
              isMonthly ? " block" : "hidden"
            }`}
          >
            {plan?.monthly_price}
          </span>
        )}

        {plan?.yearly_price && (
          <span
            className={`text-2xl leading-[42px] text-dsc-title font-bold text-center  ${
              isMonthly ? "hidden" : "block"
            }`}
          >
            {plan?.yearly_price}
          </span>
        )}
        {plan?.plan_description && (
          <span className="text-xs text-center">{plan.plan_description}</span>
        )}
      </div>
      <div className="flex justify-center">
        <Button
          path={custom_link ? custom_link : "/contact-jp/"}
          size="normal"
          title={custom_link_text ? custom_link_text : "お問い合わせ"}
          isExternal={!extractInternalLink(custom_link).startsWith("/")}
          buttonSx={{
            backgroundColor: "white",
            border: `1px solid ${theme_color}`,
          }}
          textSx={{ color: theme_color }}
          buttonHoverSx={{ backgroundColor: theme_color }}
          textHoverSx={{ color: "#FFF" }}
          className="px-4 py-2 border rounded-full bg-white text-dsc-button border-dsc-button hover:bg-dsc-button hover:text-white mt-4 "
        />
      </div>
      {plan_details && (
        <div className="pt-6 leading-5 [&_li]:mb-1 [&_ul]:list-disc [&_ul]:pl-4 [&_li]:text-sm [&_p]:text-sm [&_li]:text-dsc-body [&_p:not(:last-child)]:mb-2 [&_strong]:text-dsc-title [&_strong]:font-bold [&_ul:not(:last-child)]:mb-2 [&_hr]:my-4">
          <MarkdownRenderer markdown={plan_details} />
        </div>
      )}
    </div>
  );
};
export default PricingCard;
