import React from "react";
import FreshWorks from "../components/freshworks";
import Accordion from "../components/products/accordion/Accordion";
import Feature from "../components/products/feature/Feature";
import PremiumPricing from "../components/products/harness-pricing";
import HeroSection from "../components/products/hero/HeroSection";
import DxNews from "../components/products/news-section/DxNews";
import Overview from "../components/products/overview/Overview";
import PricingSection from "../components/products/pricing";
import SectionA from "../components/products/sectionA/SectionA";
import Solution from "../components/products/solution/Solution";
import WhyChooseUs from "../components/products/why-choose-us/WhyChooseUs";
import SecurePrivacyPricing from "../components/products/secureprivacy-pricing/pricing-section/index";
import { Navigation, Section } from "../types/products";
import NavigationHelper from "../components/products/navigation/NavigationHelper";

// interface SectionProps {
//     data: Section
// }

const Mapper = ({ data, index, navigation }) => {
  switch (data.strapi_component) {
    case "sections.hero-section":
      return <HeroSection data={data} />;
    case "products.helper-navigation":
      if (data.shortcode == "[Navigation]") {
        return <NavigationHelper navigation={navigation} />;
      } else {
        return <></>;
      }
    case "sections.overview":
      return <Overview data={data} />;
    case "sections.feature":
      return <Feature data={data} isReversed={index !== 0 && index % 2 == 0} />;
    case "sections.function":
      return <Accordion data={data} />;
    case "sections.section-a":
      return <SectionA data={data} />;
    case "sections.pricing":
      return <PricingSection data={data} />;
    case "products.why-choose-us":
      return <WhyChooseUs data={data} />;
    case "products.harness-pricing":
      return <PremiumPricing data={data} />;
    case "products.secure-privacy-pricing":
      return <SecurePrivacyPricing />;
    case "sections.soultion":
      return <Solution data={data} />;
    case "sections.news-section":
      if (data?.component_shortcode === "[Dx_News]") {
        return <DxNews data={data} />;
      } else {
        return <></>;
      }
    case "products.freshworks":
      return <FreshWorks />;
    default:
      return <></>;
  }
};

export function componentsMapper(
  sections: Section[],
  navigation: Navigation[]
) {
  console.log(sections, navigation);
  return (
    <>
      {sections.map((section, index) => {
        return (
          section.strapi_component && (
            <Mapper
              data={section}
              index={index}
              key={section.id}
              navigation={navigation}
            />
          )
        );
      })}
    </>
  );
}
