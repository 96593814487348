import { graphql, useStaticQuery } from "gatsby";

export const useRundeckNews = () => {
  try {
    const data = useStaticQuery(graphql`
      query FetchRunDeckNews {
        rundeck {
          news(sort: "releaseDate:desc", pagination: { limit: 3 }) {
            data {
              attributes {
                releaseDate
                slug
                title
              }
            }
          }
        }
      }
    `);
    return data?.rundeck?.news?.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};
