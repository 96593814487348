import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Feature from "./Feature";
import ServiceCard from "./ServiceCard";
import Title from "./Title";

const About = () => {
  return (
    <div className="pt-20 bg-[#F7F7F7] pb-[50px] px-4 flex justify-center flex-col">
      <h2 className="text-4xl leading-[50px] text-black mb-5 text-center">
        Freshworksのエコシステム
      </h2>
      <p className="text-sm leading-[30px] text-black text-center mb-[50px]">
        Freshworksには
        複数の製品があり、あらゆる規模の組織にフル機能の製品スイートを提供しています。
      </p>
      <Title
        icon={
          <StaticImage
            src="../../../static/images/tp/fdesk.png"
            width={43}
            height={45}
            layout="fixed"
            alt="Freshdesk"
          />
        }
        title="Freshdesk"
      />
      <div className="max-w-[920px] mx-auto">
        <div className="flex flex-col lg:flex-row gap-[10px] mb-[30px]">
          {serviceCards.map((card) => (
            <ServiceCard data={card} key={card.id} />
          ))}
        </div>
        <div className="flex flex-col gap-y-[10px]">
          {features.map((feature) => (
            <Feature
              title={feature.title}
              key={feature.id}
              summary={feature.summary}
            />
          ))}
          <div className="mt-[30px] flex justify-center">
            <Link
              to="/tp/freshdesk/"
              className="px-5 py-3 bg-[#148FCC] transition-all duration-500 hover:bg-[#59ABEB] rounded-[30px] text-white inline-flex text-sm leading-[15px] font-medium"
            >
              Freshdeskの詳細
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const serviceCards = [
  {
    id: 1,
    title: (
      <img
        src="/images/tp/FreshdeskSupportLogo.svg"
        alt="Freshwork Support Desk"
        width="270px"
        height="30px"
      />
    ),
    list: [
      "お客様からのお問い合わせを一元管理",
      "繰り返し行われるタスクを自動化",
      "お客様の自己解決のために、AIがFAQ記事を提案",
    ],
  },
  {
    id: 2,
    title: (
      <img
        src="/images/tp/FreshdeskMessagingLogo.svg"
        alt="Freshdesk Messaging"
        width="240px"
        height="30px"
      />
    ),
    list: [
      "積極的に顧客と関わるカスタマーサービスの実現ボットによる見込み客獲得",
      "お客様ご自身で自己解決可能なセルフサービスと",
      "チャットを導入可能",
    ],
  },
  {
    id: 3,
    title: (
      <img
        src="/images/tp/FreshdeskContactCenterLogo.svg"
        alt="Freshdesk Contact Center"
        width="270px"
        height="30px"
      />
    ),
    list: [
      "グローバル事業でも利用可能なIP電話システム",
      "通話のモニタリングや通話指標の分析も可能",
      "通話のモニタリングや通話指標の分析も可能",
      "国内のローカル番号による通話が可能",
    ],
  },
];

const features = [
  {
    id: 1,
    title: "Freshdesk Omnichannel Suite",
    summary: "簡単に利用できるオムニチャネルサービスでお客様を喜ばせましょう",
  },
  {
    id: 2,
    title: "Freshdesk Support Desk",
    summary: "最高のカスタマーサービスを提供する総合的なヘルプデスク",
  },
  {
    id: 3,
    title: "Freshchat",
    summary:
      "デジタルテクノロジーを活用する現代のお客様向けのメッセージング＆チャットボットによる初期サポート。",
  },
  {
    id: 4,
    title: "Freshdesk Contact Center",
    summary:
      "満足度の高いカスタマーエクスペリエンスを叶える信頼できるスマートなコールセンターソリューション。",
  },
  {
    id: 5,
    title: "Freshdesk Customer Success",
    summary: "収益の確保、顧客生涯価値の向上、顧客との関係強化を実現します。",
  },
];

export default About;
