import React from "react";
import MarkdownRenderer from "../../common/MarkdownRenderer";

const DesktopAccordionTabs = ({ data, activeIndex, setActiveIndex }) => {
  const accordions = data?.details;
  return (
    <>
      <div className="md:basis-1/3">
        <ul className="[&_li]:text-left">
          {accordions.map((accordion, index) => {
            const isActive = index == activeIndex;
            const background = isActive
              ? "#F7F6F3"
              : data?.theme_color || "#F7F6F3";
            const color = isActive
              ? data?.theme_color || "#2A2A2A"
              : data?.theme_color
              ? "#FFF"
              : "#2A2A2A";
            return (
              <button
                className={`px-[25px] py-5 text-sm leading-5 w-full ${
                  index !== accordions.length - 1 &&
                  "border-b border-[#00000015]"
                }`}
                style={{ background: background, color: color }}
                key={index}
                onClick={() => setActiveIndex(index)}
              >
                <li>{accordion.answer}</li>
              </button>
            );
          })}
        </ul>
      </div>
      <div className="md:basis-2/3 bg-[#F7F6F3] p-5 [&_p]:leading-[30px] [&_ul]:list-disc [&_ul]:pl-4">
        <MarkdownRenderer
          markdown={accordions[activeIndex]?.content}
        />
      </div>
    </>
  );
};

export default DesktopAccordionTabs;
