import React, { useState } from "react";
import PricingCard from "./pricingCard";
const PricingSection = ({ data }) => {
  const isToggleHidden =
    !data?.plans[0]?.yearly_price || !data?.plans[0]?.monthly_price;
  const pricing_theme = data?.btn_color_theme || "#3480E3";
  const [isMonthly, togglePricing] = useState(true);

  return (
    <div className="pb-[72px] px-4 lg:px-20 relative">
      {data?.anchor_id && (
        <span
          className="absolute left-0 top-[-150px]"
          id={data?.anchor_id?.replace("#", "")}
        ></span>
      )}
      <div className="mb-12">
        <h2 className="text-center text-2xl leading-7 font-bold mb-6">価格</h2>
        <h3 className="text-center text-lg leading-6 font-bold text-dsc-body">
          {data?.title}
        </h3>
        {data?.pricing_description && (
          <h3 className="text-center text-base leading-6 font-bold mt-4 text-dsc-body">
            {data?.pricing_description}
          </h3>
        )}
      </div>
      {!isToggleHidden && (
        <div className="flex items-center justify-center gap-2 mx-auto font-semibold mb-[60px]">
          Monthly
          <label
            htmlFor="checked-toggle"
            className="relative inline-flex items-center cursor-pointer"
          >
            <input
              type="checkbox"
              value=""
              id="checked-toggle"
              className="sr-only peer"
              onClick={() => togglePricing(!isMonthly)}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-[#189e3e5d] dark:peer-focus:ring-[#189e3f] dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#189e3f]"></div>
          </label>
          Annually
        </div>
      )}
      <div className="flex overflow-x-auto justify-center gap-4 flex-row">
        {data?.plans?.map((plan, index) => (
          <PricingCard
            isMonthly={isMonthly}
            plan={plan}
            key={index}
            theme_color={pricing_theme}
            custom_link={data?.custom_link || ""}
            custom_link_text={data?.custom_link_text || ""}
          />
        ))}
      </div>
    </div>
  );
};
export default PricingSection;
