import React from "react";
import { formateDate } from "../../../utils/helpers";
import CategoryBadge from "../../common/CategoryBadge";

const ProductNewsCard = ({ link, releaseDate, newsTitle, companyName }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div className="border-b border-dsc-softgray">
        <div className="flex items-center gap-2 py-3 px-1 hover:scale-[0.97] hover:cursor-pointer transition-all duration-300 ease-in-out">
          <div className="flex flex-col gap-y-1 md:gap-y-[6px]">
            <div className="flex items-center gap-x-2">
              <span className="font-bold text-xs leading-[14.06px] md:text-sm md:leading-4 text-dsc-body">
                {formateDate(releaseDate)}
              </span>
              <CategoryBadge category={`${companyName}関連`} />
            </div>
            <div className="flex items-start gap-x-1">
              <h3 className="text-sm md:text-lg leading-[16.41px] font-semibold md:leading-[21.09px]">
                {newsTitle}
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline md:block shrink-0 -mt-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ProductNewsCard;
