import React from "react";
import useDxNews from "../../../hooks/useDxNews";
import ProductNewsCard from "./ProductNewsCard";
import { availableQueries, StaticQueries } from "./StaticQueries";

const DxNews = ({ data }) => {
  const limit = availableQueries.includes(data?.company_name) ? 3 : 5;
  const newsData =
    data?.company_name &&
    useDxNews(data.company_name, data?.related_keywords, limit);

  // Loading Skeletion Goes Here
  if (newsData?.isLoading) {
    return <></>;
  }

  return (
    <div className="px-4 lg:px-20 mb-[72px] relative">
      <span className="absolute top-[-150px] left-0" id="news"></span>
      <h2 className="dsc-h2 text-center mb-6 md:mb-12 leading-[21.09px] md:leading-[28px]">
        お知らせ
      </h2>
      <div className="mb-6 md:mb-10">
        {!newsData?.hasError &&
          newsData?.data?.map((news, index) => {
            return (
              <ProductNewsCard
                link={news.link}
                releaseDate={news?.releasedate}
                newsTitle={news?.newstitle}
                companyName={data?.related_keywords || data?.company_name}
                key={index}
              />
            );
          })}
        {limit == 3 && (
          <StaticQueries
            company_name={data?.related_keywords || data?.company_name}
          />
        )}
      </div>
    </div>
  );
};

export default DxNews;
