import React from "react";

interface TitleProps {
  icon: React.ReactNode;
  title: string;
}

const Title: React.FC<TitleProps> = ({ icon, title }) => {
  return (
    <div className="flex gap-x-[5px] text-4xl leading-[50px] text-black mb-[30px] justify-center">
      {icon && icon}
      {title && title}
    </div>
  );
};

export default Title;
