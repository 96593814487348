import React, { useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import DesktopAccordionTabs from "./DesktopAccordionTabs";
import MobileAccordionTabs from "./MobileAccordionTabs";

const Accordion = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const screen = useWindowSize();
  return (
    <div className="pb-[72px] px-4 lg:px-20 relative">
      {data?.anchor_id && (
        <span
          className="absolute top-[-150px] left-0"
          id={data.anchor_id.replace("#", "")}
        ></span>
      )}
      <h2 className="text-center text-2xl leading-7 text-dsc-title mb-12 font-bold">
        {data?.title || "機能"}
      </h2>
      <div
        className="flex flex-col md:flex-row items-stretch rounded-[5px] overflow-hidden"
        style={{ background: data?.theme_color ? data?.theme_color : "#FFF" }}
      >
        {screen?.width && screen.width > 768 ? (
          <DesktopAccordionTabs
            data={data}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        ) : (
          <MobileAccordionTabs
            data={data}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </div>
    </div>
  );
};

export default Accordion;
