import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { extractInternalLink, purifiyImageData } from "../../../utils/helpers";
import Button from "../../common/Button";
import MarkdownRenderer from "../../common/MarkdownRenderer";
import TwoColGrid from "../../common/TwoColGrid";

type ProductOverviewType = {
  data: {
    title: string;
    anchor_id?: string;
    has_no_margin?: boolean;
    description: {
      data: {
        description: string;
      };
    };
    image?: {
      alternativeText: string;
    };
    is_default_title_hidden?: boolean;
    is_title_center?: boolean;
    btn_link?: string;
    btn_text?: string;
    btn_bg_color?: string;
    btn_text_color?: string;
  };
};

const Overview: React.FC<ProductOverviewType> = ({ data }) => {
  const bannerImage = data?.image && purifiyImageData(data?.image);

  return (
    <div
      className={`px-4 lg:px-20 relative ${
        data?.has_no_margin ? "" : "pb-[72px]"
      }`}
    >
      {data?.anchor_id && (
        <span
          className="absolute left-0 top-[-150px]"
          id={data?.anchor_id?.replace("#", "")}
        ></span>
      )}
      {!data?.is_default_title_hidden && (
        <h2 className="text-dsc-title dsc-h2 text-center leading-7 mb-12">
          製品概要
        </h2>
      )}
      <div>
        <TwoColGrid
          firstSection={
            <TextSection
              title={data?.title}
              is_title_center={data?.is_title_center}
              markdown={data.description}
              btn_link={data?.btn_link}
              btn_text={data?.btn_text}
              btn_text_color={data?.btn_text_color}
              btn_bg_color={data?.btn_bg_color}
            />
          }
          secondSection={
            bannerImage?.gatsbyImageData ? (
              <GatsbyImage
                image={bannerImage.gatsbyImageData}
                objectFit="cover"
                alt={bannerImage.alt}
              />
            ) : bannerImage?.url ? (
              <img src={bannerImage.url} loading="lazy" alt={bannerImage.alt} />
            ) : null
          }
        />
      </div>
    </div>
  );
};

const TextSection = ({
  markdown,
  btn_link,
  btn_text,
  title,
  btn_text_color,
  btn_bg_color,
  is_title_center = false,
}) => {
  const purifiedLink = btn_link && extractInternalLink(btn_link);
  return (
    <div>
      {title && (
        <h3
          className={`mb-6 text-[20px] text-dsc-title leading-8 font-bold ${
            is_title_center && "text-center"
          }`}
        >
          {title}
        </h3>
      )}
      <div className="prose prose-headings:text-dsc-title prose-headings:font-normal prose-headings:text-lg prose-headings:mb-2 w-full max-w-none [&_p]:text-sm [&_p]:leading-[22px] text-dsc-body [&_p]:mb-2 [&_ol]:list-decimal [&_ol]:pl-6 [&_ul]:list-disc [&_ul]:pl-6 [&_li]:mb-1">
        <MarkdownRenderer markdown={markdown} />
      </div>
      {btn_link && btn_text && (
        <Button
          size="normal"
          isExternal={!purifiedLink.startsWith("/")}
          className="mt-5"
          textSx={{ color: btn_text_color }}
          textHoverSx={{ color: btn_bg_color }}
          buttonSx={{ backgroundColor: btn_bg_color }}
          buttonHoverSx={{
            backgroundColor: "transparent",
            border: `1px solid ${btn_bg_color}`,
          }}
          path={btn_link}
          title={btn_text}
        />
      )}
    </div>
  );
};

export default Overview;
