import React from "react";

interface CategoryBadgeProps {
  category: string;
}
const CategoryBadge: React.FC<CategoryBadgeProps> = ({
  category,
}: CategoryBadgeProps) => {
  return (
    <span className="font-bold text-dsc-darkshade bg-dsc-white-smoke border border-dsc-softgray px-2 py-[2px] text-[9px] leading-[11.72px]">
      {category}
    </span>
  );
};

export default CategoryBadge;
