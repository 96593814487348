import React, { useState } from "react";
import { Switch } from "@headlessui/react";

function PricingProductCard({ setEnableProductCount, pricingProducts }) {
  const onSelectionChange = (index: number) => {
    setSelectProducts((prevProducts) => {
      // Copy the array to avoid alias conflict!
      const prev = [...prevProducts];
      prev[index] = !prev[index];
      // If no product is selected, selected first proudct as default
      if (!prev.some((proudct) => proudct)) {
        prev[0] = true;
      }
      setEnableProductCount(prev.filter((e) => e).length);
      return prev;
    });
  };

  const [selectProducts, setSelectProducts] = useState([true, false, false]);
  return (
    <div className="border border-dsc-theme-divider shadow-sm py-6 text-center h-full w-fit px-5 rounded-3xl space-y-4">
      {pricingProducts?.map((product: string, index: number) => (
        <div key={product} className="flex items-center gap-x-4 ">
          <span className="text-left w-[200px] xl:w-[120px]">{product}</span>
          <Switch
            checked={selectProducts[index]}
            onChange={() => onSelectionChange(index)}
            className={`${
              selectProducts[index] ? "bg-[#24B04B]" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Enable notifications</span>
            <span
              className={`${
                selectProducts[index] ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white`}
            />
          </Switch>
        </div>
      ))}
    </div>
  );
}
export default PricingProductCard;
