import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { twMerge } from "tailwind-merge";
import { HeroTypes } from "../../../types/product-components";
import { extractInternalLink, purifiyImageData } from "../../../utils/helpers";
import Button from "../../common/Button";
import Webinar from "../webinar/Webinar";

const HeroSection: React.FC<HeroTypes> = ({ data }: HeroTypes) => {
  console.log(data);
  const S_products = [
    "Smartsheet",
    "Adobe Experience Cloud",
    "Harness",
    "PagerDuty",
  ];
  const bgImage =
    data?.background_img && purifiyImageData(data?.background_img);
  const bannerImage = data?.banner_img && purifiyImageData(data?.banner_img);
  const hasNoImage = !bannerImage;
  const iconTitle = data?.icon_title && purifiyImageData(data?.icon_title);

  return (
    <div className="relative">
      {data?.webinar.length > 0 && <Webinar data={data.webinar} />}
      <div
        className={twMerge(
          `w-full h-full flex gap-x-8 lg:gap-x-[42px] gap-y-0 relative md:flex-row lg:h-[400px] lg:items-center lg:py-0 ${
            data?.has_no_margin ? "" : "mb-[72px]"
          } ${hasNoImage ? "justify-center lg:px-20" : "lg:justify-between"} ${
            S_products.includes(`${data?.title}`)
              ? "flex-col-reverse pb-10 md:items-center"
              : "flex-col py-10"
          }`
        )}
        style={!bgImage ? { backgroundColor: data?.bg_color || "#000" } : {}}
      >
        {bgImage?.gatsbyImageData ? (
          <GatsbyImage
            image={bgImage.gatsbyImageData}
            objectFit="cover"
            loading="eager"
            style={{
              position: "absolute",
              zIndex: "-10",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
            }}
            alt={bgImage.alt}
          />
        ) : (
          bgImage?.url && (
            <img
              src={bgImage.url}
              alt={bgImage.alt}
              className="absolute -z-10 w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat object-cover"
            />
          )
        )}
        <div
          className={twMerge(
            `px-4 lg:pr-0 lg:pl-20 flex-1 ${
              hasNoImage && "pl-4 lg:px-0 lg:basis-full"
            }`
          )}
        >
          {data.icon_title ? (
            iconTitle?.gatsbyImageData ? (
              <GatsbyImage
                image={iconTitle.gatsbyImageData}
                alt={iconTitle.alt}
                objectFit="cover"
                loading="eager"
              />
            ) : (
              <img
                src={iconTitle?.url}
                alt={iconTitle?.alt}
                width="245px"
                height="40px"
                loading="eager"
                className="mx-auto md:ml-0 mb-4"
              />
            )
          ) : (
            <h1
              className={twMerge(
                `dsc-h1-lg font-bold text-dsc-title mb-6 font-sub ${
                  hasNoImage
                    ? "text-white text-center"
                    : " text-center md:text-left"
                }`
              )}
              style={data?.title_color ? { color: data.title_color } : {}}
            >
              {data.title}
            </h1>
          )}
          <h2
            className={twMerge(
              `max-w-[560px] text-xl font-bold text-dsc-title ${
                hasNoImage
                  ? "text-white text-center max-w-none"
                  : "text-center mx-auto md:ml-0 md:text-left"
              }`
            )}
            style={
              data?.other_text_color ? { color: data?.other_text_color } : {}
            }
          >
            {data.subtitle}
          </h2>
          <p
            className={twMerge(
              `max-w-[560px] dsc-p-big mt-1 mb-7 text-dsc-body ${
                hasNoImage
                  ? "text-white text-center max-w-none"
                  : "text-center mx-auto md:ml-0 md:text-left"
              }`
            )}
            style={
              data?.other_text_color ? { color: data?.other_text_color } : {}
            }
          >
            <span className="leading-[21px]">{data.summary}</span>
          </p>
          <div
            className={twMerge(
              `flex ${
                hasNoImage
                  ? "justify-center items-center"
                  : "justify-center md:justify-start"
              }`
            )}
          >
            {data.link && (
              <Button
                className="mr-2"
                size="normal"
                path={extractInternalLink(data.link)}
                title={data?.link_text || "お問い合わせ"}
                buttonSx={
                  data?.link_color ? { background: data.link_color } : {}
                }
                textSx={
                  data?.link_text_color ? { color: data.link_text_color } : {}
                }
                buttonHoverSx={{ background: data?.link_hover_color }}
                textHoverSx={
                  data?.link_hover_color
                    ? { color: data?.link_text_hover_color }
                    : {}
                }
              />
            )}
            {data.seconday_link && (
              <Button
                isExternal={true}
                variant="normal"
                size="normal"
                path={data.seconday_link}
                title={data?.secondary_link_text || "価格"}
                buttonSx={
                  data?.secondary_link_bg_color
                    ? { background: data.secondary_link_bg_color, border: 0 }
                    : {}
                }
                buttonHoverSx={{
                  background: "transparent",
                  border: `1px solid ${data?.secondary_link_hover_border_color}`,
                }}
                textSx={
                  data?.secondary_link_text_color
                    ? { color: data.secondary_link_text_color }
                    : {}
                }
                textHoverSx={
                  data?.link_hover_color
                    ? { color: data?.secondary_link_hover_text_color }
                    : {}
                }
              />
            )}
          </div>
        </div>
        {data.banner_img && (
          <div
            className={twMerge(
              `px-0 md:px-4 h-full items-center lg:pl-0 flex-1 ${
                S_products.includes(`${data?.title}`)
                  ? "flex lg:pr-0"
                  : "hidden md:flex lg:pr-20"
              }`
            )}
          >
            {bannerImage?.gatsbyImageData ? (
              <GatsbyImage
                className={twMerge(
                  S_products.includes(`${data.title}`) ? "h-full" : ""
                )}
                image={bannerImage.gatsbyImageData}
                objectFit="cover"
                alt={bannerImage.alt}
              />
            ) : (
              bannerImage?.url && (
                <img
                  src={bannerImage.url}
                  loading="lazy"
                  alt={bannerImage.alt}
                />
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroSection;
